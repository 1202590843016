import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: "10px",
    overflow: "hidden",
  },
  scroll: {
    width: 320,
    height: "90%",
    borderRadius: "10px",
    overflow: "scroll",
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: "100%",
    marginLeft: 350,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function APIList({ data }) {
  const classes = useStyles();

  const [closedTopLevel, setClosedTopLevel] = useState({});
  const handleTopLevelClick = entry => () =>
    setClosedTopLevel(prev => ({ ...prev, [entry]: !prev[entry] }));

  const [closedSecondLevel, setClosedSecondLevel] = useState({});
  const handleSecondLevelClick = entry => () =>
    setClosedSecondLevel(prev => ({ ...prev, [entry]: !prev[entry] }));

  return (
    <div className={classes.container}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            API Reference
          </ListSubheader>
        }
        className={classes.scroll}
      >
        {Object.entries(data).map(([modType, modInfo]) => (
          <React.Fragment key={modType}>
            <ListItem button onClick={handleTopLevelClick(modType)}>
              <ListItemText primary={modInfo.name} />
              {!closedTopLevel[modType] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={!closedTopLevel[modType]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {Object.entries(modInfo.items).map(([group, groupInfo]) => {
                  const key = `${modType}_${group}`;
                  return (
                    <React.Fragment key={key}>
                      <ListItem
                        onClick={handleSecondLevelClick(key)}
                        button
                        className={classes.nested}
                      >
                        <ListItemText primary={groupInfo.name} />
                        {!closedSecondLevel[group] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={!closedSecondLevel[key]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {groupInfo.items.map(groupEntry => (
                            <ListItem
                              key={groupEntry.title}
                              button
                              onClick={() => {
                                window.location.href = `#${groupEntry.id}`;
                              }}
                              className={classes.nested}
                            >
                              <ListItemIcon>
                                <ArrowRightOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary={groupEntry.title} />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                })}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}
