import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useSubheader } from "../../../../_metronic/layout";
import * as apiReference from "../_redux/apiReferenceRedux";
import APIList from "../components/APIList";
import APIItem from "../components/APIItem";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginLeft: 350,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
}));

const ApiReferencePage = ({ apiReferenceData, requestAPIReferenceData }) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("");
  const classes = useStyles();

  const data = apiReferenceData.reduce((acc, item) => {
    const { bravonModuleType, bravonModule, group, groupTitle } = item;
    const mod = acc[bravonModuleType] || {};
    const groups = mod.items || {};
    const grp = groups[group] || {};
    const items = grp.items || [];

    return {
      ...acc,
      [bravonModuleType]: {
        ...mod,
        type: bravonModuleType,
        name: bravonModule.replaceAll("_", " "),
        items: {
          ...groups,
          [group]: {
            ...grp,
            name: groupTitle.replaceAll("_", " "),
            items: items.concat({
              ...item,
              id: `${item.bravonModuleType}_${item.group}_${item.type}_${
                item.name
              }`,
            }),
          },
        },
      },
    };
  }, {});
  window.data = data;

  useEffect(
    () => {
      requestAPIReferenceData();
    },
    [requestAPIReferenceData],
  );

  return (
    <div className="apireference row">
      <div className="">
        <APIList data={data} />
      </div>
      <div className={classes.root}>
        {Object.values(data).map(mod =>
            <div id={mod.type}>
              {Object.values(mod.items).map(group =>
                    group.items.map(item => <APIItem key={item.id} item={item} />),
              )}
            </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(
  connect(
    ({ apiReference: apiR }) => ({ apiReferenceData: apiR.data }),
    apiReference.actions,
  )(ApiReferencePage),
);
