import React from "react";
import APIItemSection from "./APIItemSection";

export default function APIItem({ item }) {
  const sections = ["header", "parameter", "success", "error"].reduce(
    (acc, name) => {
      const section = item[name];
      if (!section) {
        return acc;
      }

      const fields = section.fields ? Object.entries(section.fields) : [];
      const examples = section.examples || [];
      return acc.concat({ name, fields, examples });
    },
    [],
  );

  return (
    <div className="api-ref-info-container" id={item.id}>
      <article>
        <div className="pull-left">
          <h1>{item.name.replaceAll("_", " ")}</h1>
        </div>
        <div className="clearfix" />
        <div dangerouslySetInnerHTML={{ __html: item.description }} />
        <span className={`type type__${item.type.toLowerCase()}`}>
          {item.type}
        </span>
        <pre className="prettyprint language-html prettyprinted">
          <code>
            <span className="pln">{item.url}</span>
          </code>
        </pre>
        {sections.map(({ name, fields, examples }) => (
          <APIItemSection key={name} fields={fields} examples={examples} />
        ))}
      </article>
    </div>
  );
}
