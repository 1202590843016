import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardHome } from "./pages/Dashboard";
import { Documentation } from "./pages/Documentation";
import { UseCases } from "./pages/UseCases";
import { SdksTool } from "./pages/SdksTools";
import { Integrations } from "./pages/Integration";
import ApiReference from "./modules/APIReference/pages/ApiReferencePage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage"),
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage"),
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage"),
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */
        /*<Redirect exact from="/" to="/dashboard"/>*/}

        <ContentRoute path="/documentation" component={Documentation} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/use-cases" component={UseCases} />
        <ContentRoute path="/sdks-tools" component={SdksTool} />
        <ContentRoute path="/integrations" component={Integrations} />
        <ContentRoute path="/api-reference" component={ApiReference} />

        {/* Route path have to be on last  */}
        <ContentRoute path="/" component={DashboardHome} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
