import React from "react";
import {useSubheader} from "../../_metronic/layout";

export const Integrations = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Integrations");

  return (<>

    <div className="dashboard-main">
      <p>Bravon is a platform that allows companies to drive engagement and boost performance. </p>

      <div className="dashboard-block">
        <div className="dashboard-block-item">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="img-integrations" ><img src="/media/logos/jira-software-blue.png" alt=""/></div>
                <p>Create user stories and issues, plan sprints, and distribute tasks across your software team.</p>
                <p>Prioritize and discuss your team’s work in full context with complete visibility.</p>
                <p>Ship with confidence and sanity knowing the information you have is always up-to-date.</p>
                <p>Improve team performance based on real-time, visual data that your team can put to use.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <button type="button" className="btn btn-primary">Learn More</button>
          </div>
        </div>

        <div className="dashboard-block-item">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="img-integrations" ><img src="/media/logos/logo-woocommerce.png" alt=""/></div>
                <p>WooCommerce is a flexible, open-source commerce solution built on WordPress. The company started in 2008 as WooThemes and in 2017 decided to focus exclusively on eCommerce.</p>
                <p>Today, WooCommerce empowers small and medium businesses to build exactly the store they want and sell online. Our legacy as an all-remote company endures.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <button type="button" className="btn btn-primary">Learn More</button>
          </div>
        </div>

        <div className="dashboard-block-item">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="img-integrations" ><img src="/media/logos/logo-salesforce.png" alt=""/></div>
                <p>Deliver the personalized experiences your customers expect by using the integrated CRM platform that we call Salesforce Customer 360. It provides powerful, connected products for improving your marketing, sales, commerce, service, IT, and more.</p>
                <p>Ever since we started operating out of a small San Francisco apartment in 1999.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <button type="button" className="btn btn-primary">Learn More</button>
          </div>
        </div>





      </div>

    </div>




  </>);
};
