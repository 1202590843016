import React from "react";
import PropTypes from "prop-types";


export default function DashboardCard({ card }) {
    return (
        <div className="dashboard-block-item">
            <div className="dashboard-block-item-top">
                <div className="row">
                    <div className="col-lg-9 col-sm-12">
                        <p>{card.description}</p>
                        <h2>{card.title}</h2>
                    </div>
                    <div className="col-lg-3">
                        <i className={card.iconClass}/>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="dashboard-block-item-bottom">
                <div className="row">
                    {card.links.overview.length > 0 && (<div className="col-lg-4"><a href={card.links.overview}><i className="far fa-file-alt"/>Overview</a></div>)}
                    {card.links.guides.length > 0 && (<div className="col-lg-3"><a href={card.links.guides}><i className="far fa-window-restore"/>Guides</a></div>)}
                    {card.links.apiReference.length > 0 && (<div className="col-lg-5"><a href={card.links.apiReference}><i className="far fa-file-code"/>API Reference</a></div>)}
                </div>
            </div>
        </div>
    );
}

DashboardCard.propTypes = {
    card: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        iconClass: PropTypes.string.isRequired,
        links: PropTypes.shape({
            overview: PropTypes.string,
            guides: PropTypes.string,
            apiReference: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};