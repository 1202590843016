import React from "react";
import {useSubheader} from "../../_metronic/layout";
import DashboardCard from "../modules/Dashboard/components/DashboardCard";

export const DashboardHome = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard");
  const cards = [
    {
      title: 'Authentication',
      description: 'All requests to API require authentication. This is achieved by sending a valid OAuth access token in the request header',
      iconClass: 'fas fa-fingerprint',
      links: {
        overview: '/documentation#authentication',
        guides: '',
        apiReference: '/api-reference#authentication',
      }
    },
    {
      title: 'User Profile',
      description: 'Be able to manage user data.',
      iconClass: 'icon-xl far fa-user-circle',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#profile',
      }
    },
    {
      title: 'Inbox',
      description: 'Access information and communications of your company as well as important surveys.',
      iconClass: 'icon-xl far fa-clock',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#inbox',
      }
    },
    {
      title: 'Perform',
      description: 'Set personalized team journeys based on your company needs and create challenges, surveys inside the company to activate the positive peer effect.',
      iconClass: 'fas fa-tachometer-alt',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#perform',
      }
    },
    {
      title: 'Connect',
      description: 'Be able to connect to different users of your community and create multiple and different channels to talk and change some ideas!',
      iconClass: 'fas fa-plug',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#connect',
      }
    },
    {
      title: 'Academy',
      description: 'Bravon Academy uses a Microlearning method for teaching your audience as well as quizzes to evaluate how the specific course is going',
      iconClass: 'fas fa-laptop-housefas fa-laptop-housefas fa-laptop-house',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#academy',
      }
    },
    {
      title: 'Wallet/Rewards',
      description: 'Add a wallet to your team and activate the rewards. You can include vouchers, coupons, coins and allow tips between team members. Improve the motivation of your team!',
      iconClass: 'fas fa-wallet',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#wallet',
      }
    },
    {
      title: 'Marketplace',
      description: 'Bravon’s Gamified Marketplace is a simple way to convert your online business into a powerful Gamified platform increasing the engagement and performance of your visitors',
      iconClass: 'fas fa-wallet',
      links: {
        overview: '/documentation#',
        guides: '',
        apiReference: '/api-reference#marketplace',
      }
    }
  ]

  return (<>
    <div className="dashboard-main">
      <p>Bravon is a platform that allows companies to drive engagement and boost performance.</p>

      <div className="dashboard-block">
        {cards.map(item => <DashboardCard card={item} />)}
      </div>
    </div>
  </>);
};
