import React from "react";
import {useSubheader} from "../../_metronic/layout";

export const SdksTool = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("SDK & Tool");

  return (<>

    <div className="dashboard-main">
      <div className="dashboard-block">
        <h4>Android SDK</h4>
        <div className="android-block">
          <div className="dashboard-block-item-sdks">
            <div className="dashboard-block-item-top">
              <div className="row">
                <div className="image">
                  <img src="/media/logos/android-logo.png" alt=""/>
                </div>
                <div className="titles">
                  <h2>Bravon Core Kit (Android)</h2>
                  <p>A framework to work directly with the REST API</p>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-block-item-sdks">
            <div className="dashboard-block-item-top">
              <div className="row">
                <div className="image">
                  <img src="/media/logos/android-logo.png" alt=""/>
                </div>
                <div className="titles">
                  <h2>Bravon Core Kit (Android)</h2>
                  <p>A framework to build UI views from Bravon app</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4>Apple SDK</h4>
        <div className="android-block">
          <div className="dashboard-block-item-sdks">
            <div className="dashboard-block-item-top">
              <div className="row">
                <div className="image">
                  <img src="/media/logos/apple-logo.png" alt=""/>
                </div>
                <div className="titles">
                  <h2>Bravon Core Kit (iOS)</h2>
                  <p>A framework to work directly with the REST API</p>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-block-item-sdks">
            <div className="dashboard-block-item-top">
              <div className="row">
                <div className="image">
                  <img src="/media/logos/apple-logo.png" alt=""/>
                </div>
                <div className="titles">
                  <h2>Bravon UI Kit (iOS)</h2>
                  <p>A framework to build UI views from Bravon app</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <h4>Server SDK Tools</h4>
        <div className="android-block">
          <div className="dashboard-block-item-sdks">
            <div className="dashboard-block-item-top">
              <div className="row">
                <div className="image">
                  <img src="/media/logos/logo-js.png" alt=""/>
                </div>
                <div className="titles">
                  <h2>Bravon JS Kit (Javascript)</h2>
                  <p>A framework to work directly with the REST API using Node.JS</p>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>



  </>);
};
