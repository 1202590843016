import { API_REFERENCE_URL } from "../_redux/apiReferenceCrud";

export default function mockAPIReference(mock) {
  mock.onGet(API_REFERENCE_URL).reply(() => {
    try {
      const data = require("../../../../data/api_bravon_developers.json");
      return [200, data];
    } catch (err) {
      return [500];
    }
  });
}
