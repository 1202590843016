import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest, put } from "redux-saga/effects";
import { getAPIReferenceData } from "./apiReferenceCrud";

export const actionTypes = {
  APIReferenceDataRequested: "[Request APIReference] Action",
  APIReferenceDataLoaded: "[Load APIReference] APIReference API",
};

const initialAPIReferenceState = {
  data: [],
};

export const reducer = persistReducer(
  { storage, key: "v706-demo3-apiReference", whitelist: ["data"] },
  (state = initialAPIReferenceState, action) => {
    switch (action.type) {
      case actionTypes.APIReferenceDataLoaded: {
        const { data } = action.payload;
        return { ...state, data };
      }
      default:
        return state;
    }
  },
);

export const actions = {
  requestAPIReferenceData: () => ({
    type: actionTypes.APIReferenceDataRequested,
  }),
  fulfillAPIReferenceData: data => ({
    type: actionTypes.APIReferenceDataLoaded,
    payload: { data },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.APIReferenceDataRequested,
    function* apiReferenceDataRequested() {
      const { data } = yield getAPIReferenceData();
      yield put(actions.fulfillAPIReferenceData(data));
    },
  );
}
