import React from "react";
import {useSubheader} from "../../_metronic/layout";

export const UseCases = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Use Cases");

  return (<>

    <div className="dashboard-main">
      <div className="dashboard-block">
        <div className="dashboard-block-item-uses-cases">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12">
                <p className="sub-title">Authentication</p>
                <h2>How to authenticate with the user credentials?</h2>
                <p>Learn how to enable your customers to access to you directly from your website.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <div className="row">
              <div className="col-lg-12"><a href="">Learn More <i className="fas fa-long-arrow-alt-right"></i></a></div>
            </div>
          </div>
        </div>

        <div className="dashboard-block-item-uses-cases">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12">
                <p className="sub-title">Perform</p>
                <h2>How to create a challenge?</h2>
                <p>Learn how to enable your customers to access to you directly from your website.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <div className="row">
              <div className="col-lg-12"><a href="">Learn More <i className="fas fa-long-arrow-alt-right"></i></a></div>
            </div>
          </div>
        </div>

        <div className="dashboard-block-item-uses-cases">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12">
                <p className="sub-title">Academy</p>
                <h2>How to create a course for the academy?</h2>
                <p>Learn how to enable your customers to access to you directly from your website.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <div className="row">
              <div className="col-lg-12"><a href="">Learn More <i className="fas fa-long-arrow-alt-right"></i></a></div>
            </div>
          </div>
        </div>

        <div className="dashboard-block-item-uses-cases">
          <div className="dashboard-block-item-top">
            <div className="row">
              <div className="col-lg-12">
                <p className="sub-title">User Profile</p>
                <h2>How to chat with users?</h2>
                <p>Learn how to enable your customers to access to you directly from your website.</p>
              </div>
            </div>

          </div>
          <hr/>
          <div className="dashboard-block-item-bottom">
            <div className="row">
              <div className="col-lg-12"><a href="">Learn More <i className="fas fa-long-arrow-alt-right"></i></a></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </>);
};
