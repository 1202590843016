import React, { useState } from 'react';
import {
  AppBar,
  makeStyles,
  Paper, Tab,
  Table,
  TableBody, TableCell,
  TableHead,
  TableRow, Tabs, Typography, useTheme, withStyles,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);


export default function APIItemSection({ fields, examples }) {
  const theme = useTheme();
  const classes = useStyles();

  const [value, setValue] = useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  function handleChangeIndex(index) {
    setValue(index);
  }

  const showExamples = examples && examples.length > 0;

  return (
    <>
      {fields.map(([name, fields]) => (
        <React.Fragment key={name}>
      <h2>{name}</h2>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell width="30%">Field</StyledTableCell>
              <StyledTableCell width="10%">Type</StyledTableCell>
              <StyledTableCell width="60%">Description</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map(row => (
              <StyledTableRow key={row.field}>
                <StyledTableCell>
                  <>
                    {row.field}
                    {row.optional && (
                      <span className="label label-optional">optional</span>
                    )}
                  </>
                </StyledTableCell>
                <StyledTableCell>{row.type}</StyledTableCell>
                <StyledTableCell>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: row.description,
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
          </React.Fragment>
      ))}
      {showExamples && (
        <div className={classes.root}>
          {examples.length > 1 && (
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                {examples.map(row => (
                  <Tab key={row.title} label={row.title} />
                ))}
              </Tabs>
            </AppBar>
          )}
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            {examples.map(row => (
              <Typography
                key={row.title}
                component="div"
                dir={theme.direction}
              >
                  <pre className="prettyprint language-json prettyprinted">
                    <code>
                      <span className="pln">{row.content}</span>
                    </code>
                  </pre>
              </Typography>
            ))}
          </SwipeableViews>
        </div>
      )}
    </>
  );
}
