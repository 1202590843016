/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Brand } from "../brand/Brand";

export function Aside() {
  // const uiService = useHtmlClassService();

  return (
    <>
      {/* begin::Aside */}
      <div id="kt_aside" className={`aside aside-left d-flex `}>
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Dashboard"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="latest-project">Dashboard</Tooltip>}
                >
                  <a
                    href="/"
                    className={`nav-link btn btn-icon btn-clean btn-lg`}
                    data-toggle="tab"
                    data-target={``}
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Layout/Layout-4-blocks.svg",
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              {/* end::Item */}

              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Documentations"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="metronic-features">Documentation</Tooltip>
                  }
                >
                  <a
                    href="/documentation"
                    className={`nav-link btn btn-icon btn-clean btn-lg`}
                    data-toggle="tab"
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Files/Selected-file.svg",
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              {/* end::Item */}

              {/* begin::Item
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Use Cases"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="latest-reports">Use Cases</Tooltip>
                  }
                >
                  <a
                    href="/use-cases"
                    className={`nav-link btn btn-icon btn-clean btn-lg`}
                    data-toggle="tab"
                    data-target="#kt_aside_tab_3"
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Binocular.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              end::Item */}

              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="API Reference"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="project-management">API Reference</Tooltip>
                  }
                >
                  <a
                    href="/api-reference"
                    className="nav-link btn btn-icon btn-clean btn-lg"
                    data-toggle="tab"
                    data-target="#kt_aside_tab_4"
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Code/Code.svg")}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              {/* end::Item */}

              {/* begin::Item
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="SDKs & Tools"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="user-management">SDKs & Tools</Tooltip>}
                >
                  <a
                    href="/sdks-tools"
                    className="nav-link btn btn-icon btn-clean btn-lg"
                    data-toggle="tab"
                    data-target="#kt_aside_tab_5"
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              end::Item */}

              {/* begin::Item
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Integrations "
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="finance-accounting">Integrations</Tooltip>
                  }
                >
                  <a
                    href="/integrations"
                    className="nav-link btn btn-icon btn-clean btn-lg"
                    data-toggle="tab"
                    data-target="#kt_aside_tab_6"
                    role="tab"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Code/Git4.svg")}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}

          {/* end::Footer */}
        </div>
        {/* end::Primary */}
      </div>
      {/* end::Aside */}
    </>
  );
}
