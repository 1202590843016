import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

export default [
  {
    id: 1,
    username: "support@bravon.io",
    password: "Avnoconn2014!",
    email: "support@bravon.io",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Bravon Support",
    occupation: "",
    companyName: "Bravon",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 2,
    username: "luis.henriques@avnoconn.com",
    password: "Avnoconn2014!",
    email: "luis.henriques@avnoconn.com",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Luis Henriques",
    occupation: "",
    companyName: "Bravon",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 3,
    username: "pedro.lopes@avnoconn.com",
    password: "Avnoconn2014!",
    email: "pedro.lopes@avnoconn.com",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [2], // Manager
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Pedro Lopes",
    occupation: "",
    companyName: "Bravon",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 4,
    username: "team@digitalscaler.eu",
    password: "fZqJu2qS7d",
    email: "team@digitalscaler.eu",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "DigitalScaler Developers",
    occupation: "",
    companyName: "DigitalScaler",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 5,
    username: "rodrigue.ribeiro@bravon.io",
    password: "SsppLucFxLEenS9n",
    email: "rodrigue.ribeiro@bravon.io",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Rodrigue Ribeiro",
    occupation: "",
    companyName: "Bravon",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 6,
    username: "team@weelodge.biz",
    password: "E4twrdUhzh",
    email: "team@weelodge.biz",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Team Weelodge",
    occupation: "",
    companyName: "Weelodge",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 7,
    username: "team@neofin-technologies.com",
    password: "zgoXDbveCm",
    email: "team@neofin-technologies.com",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b507",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161daa07",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Team Neofin",
    occupation: "",
    companyName: "Neofin",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 8,
    username: "team@collectifenergie.com",
    password: "AlTpHCrKRj",
    email: "team@collectifenergie.com",
    accessToken: "access-token-e6f4d5d4fe762035b0f95b756f0fd783",
    refreshToken: "access-token-27c95009d6084e1cec5ae362679e7c18",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Team Collectif Energie",
    occupation: "",
    companyName: "Collectif Energie",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 9,
    username: "team@pureliink.com",
    password: "SLAE4l0KUG",
    email: "team@pureliink.com",
    accessToken: "access-token-d1278c03a9c74fcda4a637c66d2e8af8",
    refreshToken: "access-token-b702f2fbe4ecdc16d6d923ec831399e5",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Team Pureliink",
    occupation: "",
    companyName: "Pureliink",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
  {
    id: 10,
    username: "demo@bravon.io",
    password: "Demo2024!",
    email: "demo@bravon.io",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cd",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90ab",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/logos/logo-small-bravon.png"),
    fullname: "Bravon Demo",
    occupation: "",
    companyName: "Bravon",
    phone: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: ""
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: ""
    }
  },
];
