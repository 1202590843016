import React, {useState} from "react";
import { useSubheader } from "../../_metronic/layout";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { ListSubheader } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { colors } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { SnackbarContent } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const { amber, green } = colors;

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: "10px",
    overflow: "hidden",
  },
  scroll: {
    width: 320,
    height: "90%",
    borderRadius: "10px",
    overflow: "scroll",
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    width: "100%",
    marginLeft: 350,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

export const Documentation = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("");

  const classes = useStyles1();

  const [closedTopLevel, setClosedTopLevel] = useState({});
  const handleTopLevelClick = entry => () =>
    setClosedTopLevel(prev => ({ ...prev, [entry]: !prev[entry] }));

  const executeScroll = (ref) => () => {
    window.location.href = `/documentation#${ref}`;
  }

  const executeRedirect = (href) => () => {
    window.location.href = href;
  }

  return (
    <div className="apireference row">
      <div className="">
        <div className={classes.container}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Documentation
              </ListSubheader>
            }
            className={classes.scroll}
          >
            <ListItem button onClick={handleTopLevelClick("Introduction")}>
              <ListItemIcon>
                <GroupOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Introduction" />
              {!closedTopLevel["Introduction"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse  in={!closedTopLevel["Introduction"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem onClick={executeScroll("Introduction_Overview")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_REST")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="REST API" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Dates")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Multi-Tenancy" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Dates")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="User Roles" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Request_Response")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Request/Response" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Errors")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Errors" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Pagination")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Pagination" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Populate")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Expanding Responses (Populate)" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Introduction_Dates")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Managing Date/Time" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleTopLevelClick("Authentication")}>
              <ListItemIcon>
                <GroupOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Authentication" />
              {!closedTopLevel["Authentication"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!closedTopLevel["Authentication"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem onClick={executeScroll("Authentication_Overview")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                {/*<ListItem onClick={executeRedirect("/api-reference#authentication")} button className={classes.nested}>*/}
                {/*  <ListItemIcon>*/}
                {/*  </ListItemIcon>*/}
                {/*  <ListItemText primary="API Reference" />*/}
                {/*  <ListItemIcon>*/}
                {/*    <ArrowRightOutlinedIcon />*/}
                {/*  </ListItemIcon>*/}
                {/*</ListItem>*/}
                <ListItem onClick={executeScroll("Authentication_appIds")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="App Ids and Secrets" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={handleTopLevelClick("Academy")}>
              <ListItemIcon>
                <GroupOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Academy" />
              {!closedTopLevel["Academy"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!closedTopLevel["Academy"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem onClick={executeScroll("Academy_Overview")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Academy_Courses")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Courses" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Academy_SCORM")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="SCORM Courses" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={handleTopLevelClick("Perform")}>
              <ListItemIcon>
                <GroupOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Perform" />
              {!closedTopLevel["Perform"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={!closedTopLevel["Perform"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem onClick={executeScroll("Perform_Overview")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem onClick={executeScroll("Perform_Journeys")} button className={classes.nested}>
                  <ListItemIcon>
                  </ListItemIcon>
                  <ListItemText primary="Journeys" />
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </div>
      </div>
      <div className={classes.root}>
        <div className="container-content">
          <div className="autentication info-api-reference">
            <div className="row">
              <div className="col-lg-12">
                <div className="api-ref-info-container" id="Introduction">
                  <h1>Introduction</h1>
                  <p>The Bravon API</p>
                  <div id="Introduction_Overview">
                    <h2>Overview</h2>
                    <p>The Bravon API is organized around REST. Our API has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.</p>
                    <p>You can use the Bravon API in test mode, which does not affect your live data. The API key and Secret you use to authenticate the request determines whether the request is production mode or test mode.</p>
                    <hr/>
                  </div>
                  <div id="Introduction_REST">
                    <h2>REST API</h2>
                    <p>The whole system is separated in micro services, each one with its own responsibility and data resources.</p>
                    <div className="row img-size">
                      <div className="col-lg-1"/>
                      <div className="col-lg-10">
                        <img src="/media/documentation/schemas/bravon_api_services.png" alt=""/>
                      </div>
                      <div className="col-lg-1"/>
                    </div>
                    <br/>
                    <br/>
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>API Authentication</h6>
                        <p> * Handle authentication and tokens management</p>
                        <br/>
                        <h6>API Organizations</h6>
                        <p> * Stores and provide organizations information such as Users, teams, etc</p>
                        <p> * Responsible to send Mails and Notifications</p>
                        <br/>
                        <h6>Gamification Licensing</h6>
                        <p> * Manages Information related to organizations licencing, subscriptions, invoices and payments</p>
                        <br/>
                        <h6>Gamification Games</h6>
                        <p> * Stores and manage information related to Challenges, Journeys, Courses, Surveys</p>
                        <p> * Each organisation has its own single db instance connected to this ser</p>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  <div id="Introduction_Multi_Tenancy">
                    <h2>Multi-Tenancy</h2>
                    <p>Our back-office is based in Multi-Tenancy, this means we can specific specific configurations per tenants, and their organizations and users will inherit that configs.</p>
                    <p>For example per tenant, we can have a specific theme (brading), have custom email template, different configs per service and different domains/sub-domains for each portal. </p>
                    <div className="row img-size">
                      <div className="col-lg-1"/>
                      <div className="col-lg-10">
                        <img src="/media/documentation/schemas/bravon_tenants_tree.jpg" alt=""/>
                      </div>
                      <div className="col-lg-1"/>
                    </div>
                  </div>
                  <div id="Introduction_User_Roles">
                    <h2>User Roles</h2>
                    <div className="row img-size">
                      <div className="col-lg-1"/>
                      <div className="col-lg-10">
                        <img src="/media/documentation/schemas/bravon_user_types.png" alt=""/>
                      </div>
                      <div className="col-lg-1"/>
                    </div>
                  </div>
                  <div id="Introduction_Request_Response">
                    <h2>Request/Response</h2>
                    <p>The API will use the general http verbs <code>GET</code> <code>POST</code> <code>PUT</code> <code>DELETE</code> for all API calls.</p>
                    <p>Bravon API uses conventional HTTP response codes to indicate the success or failure of an API request.</p>
                    <p>In general: Codes in the <code>2xx</code> range indicate success. Codes in the <code>4xx</code> range indicate an error that failed given the information provided (e.g., a required parameter was omitted, a charge failed, etc.). Codes in the <code>5xx</code> range indicate an error with Bravon's servers (these are rare).</p>
                    <p>All success methods return a JSON message similar to:</p>
                    <pre className="prettyprint language-html prettyprinted" >
                  <code>
                    <span className="pln">{
                      JSON.stringify(
                        {
                          "success": true,
                          "time": "1477040651571",
                          "total": 10,
                          "data": []
                        }, undefined,2)
                    }</span>
                  </code>
                </pre>
                    <p><code>success: true</code> - Indicates the response was a success</p>
                    <p><code>time</code> - Time of the server</p>
                    <p><code>total</code> - Number of total items returned in data param</p>
                    <p><code>data</code> - Parameter that contains the response. Can be an Array, an Object JSON, a simple String or an Integer</p>
                    <hr/>
                  </div>
                  <div id="Introduction_Errors">
                    <h2>Errors</h2>
                    <p>Our API can produce errors for many reasons, such as a failed validations, invalid parameters, authentication errors, data not found.</p>
                    <p>All failure methods return a JSON message similar to:</p>
                    <pre className="prettyprint language-html prettyprinted" >
                  <code>
                    <span className="pln">{
                      JSON.stringify(
                        {
                          "success": true,
                          "time": "1477040651571",
                          "error": "ERROR_KEY_CODE",
                          "message": {}
                        }, undefined,2)
                    }</span>
                  </code>
                </pre>
                    <p><code>success: false</code> - Indicates the response was an error</p>
                    <p><code>time</code> - Time of the server</p>
                    <p><code>error</code> - Error code that identifies the type of error.</p>
                    <p><code>message</code> - Parameter that contains more details about the error. Can be an Array, an Object JSON, a simple String or an Integer</p>
                    <hr/>
                  </div>
                  <div id="Introduction_Pagination">
                    <h2>Pagination</h2>
                    <p>All top-level API resources have support for bulk fetches via "list" API methods.</p>
                    <p>These list API methods share a common structure, taking at least these three parameters: <code>index</code>, <code>offset</code>, and <code>sort</code>.</p>
                    <p><code>index</code> - A cursor for use in pagination. index is an integer that defines your place in the list</p>
                    <p><code>offset</code> - A limit on the number of objects to be returned, between 1 and 100.</p>
                    <p><code>sort</code> - Choose the parameter you need to sort the list of items</p>
                    <hr/>
                  </div>
                  <div id="Introduction_Populate">
                    <h2>Expanding Responses (Populate)</h2>
                    <p>Many objects allow you to request additional information as an expanded response by using the populate request parameter. This parameter is available on most API requests, and applies to the response of that request only.</p>
                    <p>In many cases, an object contains the <code>_id</code> of a related object in its response properties. For example, an <code>User</code> have an associated <code>Organization _id</code>. Those objects can be expanded inline with the expand request parameter. ID fields that can be expanded into objects are noted in this documentation with <code>populate</code> label.</p>
                    <p>You can use the <code>populate</code> param on any endpoint <code>GET</code> which returns expandable fields, including list, or json objects</p>
                    <p>Example without populate</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">{
                          JSON.stringify(
                            {
                              "success": true,
                              "time": 1603889093484,
                              "total": 1,
                              "data": {
                                "_id": "5c78105d0d661240292baa29",
                                "email": "use@example.com",
                                "firstName": "User",
                                "lastName": "Example",
                                "organization": "5c78105d0d661240292baa28",
                              }
                            }, undefined,2)
                        }</span>
                      </code>
                    </pre>
                    <p>Example with populate <code>(populate=organization)</code></p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">{
                          JSON.stringify(
                            {
                              "success": true,
                              "time": 1603889093484,
                              "total": 1,
                              "data": {
                                "_id": "5c78105d0d661240292baa29",
                                "email": "use@example.com",
                                "firstName": "User",
                                "lastName": "Example",
                                "organization": {
                                  "_id": "5c78105d0d661240292baa28",
                                  "name": "Organization Name Example"
                                },
                              }
                            }, undefined,2)
                        }</span>
                      </code>
                    </pre>
                    <hr/>
                  </div>
                  <div id="Introduction_Dates">
                    <h2>Managing Date/Time</h2>
                    <p>All the <code>Date</code> fields provided by the API are always displayed in milliseconds. Our servers are also configured to be at <code>UTC</code> time zone.</p>
                    <p>So all the dates send to the API (create/update items) must be converted to <code>UTC</code> and in milliseconds.</p>
                    <p>Time with Luxembourg Time Zone</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">
                          1 January 2022 13:00:00 GMT+01:00
                        </span>
                      </code>
                    </pre>
                    <p>The correct value in UTC (1 January 2022 12:00:00)</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">
                          milliseconds = 1641038400000
                        </span>
                      </code>
                    </pre>
                  </div>
                </div>
                <div className="api-ref-info-container"  id="Authentication">
                  <div id="Authentication_Overview">
                    <h1>Authentication</h1>
                    <p>All requests to API require authentication. This is achieved by sending a valid OAuth access token in the request header.</p>
                    <h2>Overview</h2>
                    <p>To get a valid access token you must use the provided <code>App ID</code> and <code>App Secret</code></p>
                    <p>Your API keys carry many privileges, so be sure to keep them secure! Do not share your secret API keys in publicly accessible areas such as GitHub, client-side code, and so forth.</p>
                    <p>Authentication to the API is performed via HTTP Basic Auth. Provide the user email and the user password as the basic auth.</p>
                    <p>In the body of the request the app id and app secret should be provided too.</p>
                    <p>Once you have an access token you need to authenticate via bearer auth (e.g., for a cross-origin request), use <code>-H "Authorization: Bearer cea529b6eaa3a230f649c4adb8f9c6b7f0129674f7588c58a2afd5e6f0d70eb5f09c"</code></p>
                    <h3>How to get an Access Token?</h3>
                    <span className="type type__post">POST</span>
                    <pre className="prettyprint language-html prettyprinted" >
                  <code>
                    <span className="pln">https://api-test.bravon.io/auth/token</span>
                  </code>
                </pre>
                    <h4>Header</h4>
                    <pre className="prettyprint language-html prettyprinted" >
                  <code>
                    <span className="pln">
                      Authorization: Basic bHVpcy5oZW5yaXF1ZXNAYXZub2Nvbm4uY29tOjEyMzQ1Njc=  // String (email:password) encoded as Base64
                    </span>
                  </code>
                </pre>
                    <h4>Body</h4>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">{
                          JSON.stringify({
                            "appId" : "{{apiKey}}",
                            "appSecret" : "{{apiSecret}}"
                          }, undefined,2)
                        }</span>
                      </code>
                    </pre>
                    <h4>Body (with organization identifier)</h4>
                    <p>This example is used when we need to specify the organization the user wants to login.</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">{
                          JSON.stringify({
                            "appId" : "{{apiKey}}",
                            "appSecret" : "{{apiSecret}}",
                            "organizationIdentifier" : "{{organizationIdentifier}}"
                          }, undefined,2)
                        }</span>
                      </code>
                    </pre>
                    <h4>Result</h4>
                    <pre className="prettyprint language-html prettyprinted" >
                  <code>
                    <span className="pln">{
                      JSON.stringify({
                        "success": true,
                        "time": 1603890783199,
                        "total": 1,
                        "data": {
                          "organization": "5947dcc1152f6e1e18db4857",
                          "user": "5947dcc1152f6e1e18db485a",
                          "loginType": "email",
                          "role": "admin",
                          "expires_in": 1606482783000,
                          "token": "29674f7588c58a2afd5e6f0d70eb5f09cd30a0ad46a898dfd64122d03c698ddf14e5a08a2afd5e6f0d70eb5f",
                          "refreshToken": "6eaa3a230f649c4adb8f9c6b7f0129674f7588c58a2afd5e6f0d70eb5f09cd30a0ad46a87f0398dfd64122d03c698ddf14e5"
                        }
                      }, undefined,2)
                    }</span>
                  </code>
                </pre>
                    <hr/>
                  </div>
                  <div id="Authentication_appIds">
                    <h2>App Ids and Secrets</h2>
                    <p>There are 3 different types of app ids, with different proposes and And they are used to identify the type of audience (through the Auth Token) and in this way to be able to manage the content of API requests, and to be able to show (or filter) specific results for normal users and admins.</p>
                    <p>For each app id, there is always a specific (unique) app secret. So they should be used always together.</p>
                    <p>Examples of app ids:</p>
                    <p><code>gamificationMb</code> - This app id is mainly used to authenticate normal users on the user portal and mobile apps. So the requests coming with this app id we can manage some content specifically to the user. For example sent the course (or journey) status of the user for that specific item, or limit the access to specific content (listing).</p>
                    <p><code>gamificationBo</code> - This app id is mainly used to authenticate admins on the back-office as well identify the requests are coming from the admin portal and send properly the info for admins.</p>
                    <p><code>gamificationAPI</code> - This app id is specific to be used for external API’s without needing to have user credentials, that’s why it should be used with a different endpoint to authenticate. The big difference between this one and the previous ones is that this one doesn't contain the information of the user id encoded in the auth token..</p>
                    <MySnackbarContentWrapper
                      variant="info"
                      className={classes.margin}
                      message="You may have different App Ids denominations, if you are using a different machine configured with Bravon. The only similarity (and unique characters) is the termination of the app id (Mb, Bo, API)"
                    />
                    <hr/>
                  </div>
                </div>
                <div className="api-ref-info-container"  id="Academy">
                  <div id="Academy_Overview">
                    <h1>Academy</h1>
                    <p>Bravon Academy uses a Microlearning method for teaching your audience as well as quizzes to evaluate how the specific course is going</p>
                    <hr/>
                  </div>
                  <div id="Academy_Courses">
                    <h2>Courses</h2>
                    <p>The course entity on bravon involves a complex schema and relationship between other database entities, like categories, modules, lessons, quiz, etc.. </p>
                    <h3>Schema Map</h3>
                    <div className="row img-size">
                      <div className="col-lg-1"/>
                      <div className="col-lg-10">
                        <img src="/media/documentation/schemas/db_star_schema-courses.png" alt=""/>
                      </div>
                      <div className="col-lg-1"/>
                    </div>
                    <p><code>Course Category</code>: Entity to group or filter courses by a specific category</p>
                    <p><code>Course</code>: Main entity that contains the global information about the course</p>
                    <p><code>Modules</code>: Modules are used to group multiple lessons, to be possible to slip the lessons in multiple topics, for example</p>
                    <p><code>Lessons</code>: Lessons are what the users will have to learn/visualize on their UI.</p>
                    <p><code>Lesson Quiz</code>: Quizzes are usually attached to a specific lesson, and they contain the information of the question and possible answers, and also the correct answer. A lesson can attach multiple quizzes</p>
                    <p><code>Quiz Answers</code>: This entity is used to save the answer of the user related to a quiz</p>
                    <p><code>Lesson Status</code>: This entity is used to save a status that the lesson will appear as done to a specific user</p>
                    <hr/>
                    <h3>Create/Edit Courses</h3>
                    <p>There are two ways to manage courses.</p>
                    <h6>Managing course, modules, lessons separately:</h6>
                    <p>So this mean you have to use a comination of multiple endpoints.</p>
                    <p>Endpoints to Create: </p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">POST /course</span>
                        <br />
                        <span className="pln"> POST /lesson-module</span>
                        <br />
                        <span className="pln"> POST /lesson</span>
                      </code>
                    </pre>
                    <p>Endpoints to Edit: </p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">PUT /course/:courseId</span>
                        <br />
                        <span className="pln"> PUT /lesson-module/:moduleId</span>
                        <br />
                        <span className="pln"> PUT /lesson/:lessonId</span>
                      </code>
                    </pre>
                    <h6>Managing course, modules, lessons in the same request:</h6>
                    <p>The bravon API is ready to handle also modules, lessons and quizzes recursively in the same endpoint to create or edit courses</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">POST /course</span>
                        <br />
                        <span className="pln"> PUT /course/:courseId</span>
                      </code>
                    </pre>
                    <p>Body example to create a new course.</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <span className="pln">{
                        JSON.stringify({
                          "isPublic": true,
                          "category": "5e21d017f2b68614cc3bd622",
                          "name": "HTML Course",
                          "summary": "HTML Course Summary",
                          "description": "HTML Course Description",
                          "motivation": "HTML Course motivation",
                          "tags": [
                            "HTML",
                            "CSS",
                            "Javascript"
                          ],
                          "highlight": true,
                          "image": {
                            "url": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                            "medium": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                            "thumb": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                          },
                          "imageSquare": {
                            "url": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                            "medium": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                            "thumb": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                          },
                          "level": 1,
                          "hardSkills": [
                            {
                              "_id": "59f83e7019b3ab2777701a92",
                              "name": "HTML"
                            }
                          ],
                          "seo": {
                            "title": "HTML Course",
                            "description": "HTML Course Description",
                            "keywords": [
                              "HTML",
                              "css"
                            ],
                            "image": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                          },
                          "isFromExternalProvider": false,
                          "certificate": {
                            "isActive": true,
                            "color": "#6edc07",
                            "link": "bravon.io",
                            "logo": {
                              "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                              "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                              "url": "https://s3.eu-central-1.amazonaws.com/media/photos/54e7bd4ab0fc0d97cbf4388f51489b5a.png"
                            },
                            "signature": {
                              "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                              "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                              "url": "https://s3.eu-central-1.amazonaws.com/media/photos/9b1bb8b2335837a994f35125734fd387.png"
                            },
                            "entityName": "Bravon",
                            "legalInfo": "In accordance with our GDPR obligations, please be informed that your data will only be stored. If you have any questions, please contact info@bravon.io"
                          },
                          "modules": [
                            {
                              "type": "lessons",
                              "name": "Introduction",
                              "description": "Description of the module",
                              "image": {
                                "url": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                                "medium": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                                "thumb": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                              },
                              "pos": 0,
                              "level": 1,
                              "lessons": [
                                {
                                  "type": "video",
                                  "contentType": "whiteboard",
                                  "name": "Learn HTML",
                                  "description": "Learn basis about HTML",
                                  "preview": {
                                    "url": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg",
                                    "medium": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg",
                                    "thumb": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg"
                                  },
                                  "video": {
                                    "url": "https://s3.eu-central-1.amazonaws.com/demo2/video_1.mp4",
                                    "thumb": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg"
                                  },
                                  "pos": 0,
                                  "level": 1,
                                  "duration": 50000,
                                  "quizGoal": null
                                },
                                {
                                  "type": "quiz",
                                  "name": "QUIZ",
                                  "description": "Description of Quiz",
                                  "preview": null,
                                  "pos": 1,
                                  "level": 1,
                                  "duration": 2000,
                                  "quizGoal": 90,
                                  "quiz": [
                                    {
                                      "question": "Qustion of the Quiz?",
                                      "image": null,
                                      "answers": {
                                        "0001": "A",
                                        "0002": "B",
                                        "0003": "C",
                                        "0004": "D"
                                      },
                                      "correctAnswer": "0001"
                                    },
                                    {
                                      "question": "Qustion of the Quiz 2?",
                                      "image": null,
                                      "answers": {
                                        "000A": "A",
                                        "000B": "B",
                                        "000C": "C",
                                        "000D": "D"
                                      },
                                      "correctAnswer": "000D"
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "type": "trophy",
                              "name": "Trophy 4",
                              "description": "",
                              "pos": 1,
                              "level": 1
                            }
                          ]
                        }, undefined,2)
                      }</span>
                    </pre>
                    <p>Body example to edit a course.</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <span className="pln">{
                        JSON.stringify(
                          {
                            "isPublic": true,
                            "category": "5e21d017f2b68614cc3bd622",
                            "name": "HTML Course",
                            "summary": "HTML Course Summary",
                            "description": "HTML Course Description",
                            "motivation": "HTML Course motivation",
                            "tags": [
                              "HTML",
                              "CSS",
                              "Javascript"
                            ],
                            "highlight": true,
                            "image": {
                              "url": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "medium": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "thumb": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "imageSquare": {
                              "url": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "medium": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "thumb": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "level": 1,
                            "hardSkills": [
                              {
                                "_id": "59f83e7019b3ab2777701a92",
                                "name": "HTML"
                              }
                            ],
                            "seo": {
                              "title": "HTML Course",
                              "description": "HTML Course Description",
                              "keywords": [
                                "HTML",
                                "css"
                              ],
                              "image": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "isFromExternalProvider": false,
                            "certificate": {
                              "isActive": true,
                              "color": "#6edc07",
                              "link": "bravon.io",
                              "logo": {
                                "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                                "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                                "url": "https://s3.eu-central-1.amazonaws.com/media/photos/54e7bd4ab0fc0d97cbf4388f51489b5a.png"
                              },
                              "signature": {
                                "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                                "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                                "url": "https://s3.eu-central-1.amazonaws.com/media/photos/9b1bb8b2335837a994f35125734fd387.png"
                              },
                              "entityName": "Bravon",
                              "legalInfo": "In accordance with our GDPR obligations, please be informed that your data will only be stored. If you have any questions, please contact info@bravon.io"
                            },
                            "modules": [
                              {
                                "_id": "5efdbc00d11162a4605ad7bb",
                                "type": "lessons",
                                "name": "Introduction",
                                "description": "Description of the module",
                                "image": {
                                  "url": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                                  "medium": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                                  "thumb": "https://s3-eu-central-1.amazonaws.com/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                                },
                                "pos": 0,
                                "level": 1,
                                "lessons": [
                                  {
                                    "_id": "5efdbc00d11162a4605ad7bf",
                                    "type": "video",
                                    "contentType": "whiteboard",
                                    "name": "Learn HTML",
                                    "description": "Learn basis about HTML",
                                    "preview": {
                                      "url": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg",
                                      "medium": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg",
                                      "thumb": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg"
                                    },
                                    "video": {
                                      "url": "https://s3.eu-central-1.amazonaws.com/demo2/video_1.mp4",
                                      "thumb": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg"
                                    },
                                    "pos": 0,
                                    "level": 1,
                                    "duration": 50000,
                                    "quizGoal": null
                                  },
                                  {
                                    "type": "video",
                                    "contentType": "whiteboard",
                                    "name": "New Video",
                                    "description": "Lesson Description",
                                    "preview": {
                                      "url": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg",
                                      "medium": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg",
                                      "thumb": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg"
                                    },
                                    "video": {
                                      "url": "https://s3.eu-central-1.amazonaws.com/demo2/video_1.mp4",
                                      "thumb": "https://s3.eu-central-1.amazonaws.com/demo2/preview_1.jpg"
                                    },
                                    "pos": 1,
                                    "level": 1,
                                    "duration": 50000,
                                    "quizGoal": null
                                  },
                                  {
                                    "_id": "5efdbc00d11162a4605ad7bc",
                                    "type": "quiz",
                                    "name": "QUIZ",
                                    "description": "Description of Quiz",
                                    "preview": null,
                                    "pos": 2,
                                    "level": 1,
                                    "duration": 2000,
                                    "quizGoal": 90,
                                    "quiz": [
                                      {
                                        "_id": "5efdbc00d11162a4605ad7be",
                                        "question": "Qustion of the Quiz?",
                                        "image": null,
                                        "answers": {
                                          "0001": "A",
                                          "0002": "B",
                                          "0003": "C",
                                          "0004": "D"
                                        },
                                        "correctAnswer": "0001"
                                      },
                                      {
                                        "_id": "5efdbc00d11162a4605ad7ba",
                                        "question": "Qustion of the Quiz 2?",
                                        "image": null,
                                        "answers": {
                                          "000A": "A",
                                          "000B": "B",
                                          "000C": "C",
                                          "000D": "D"
                                        },
                                        "correctAnswer": "000D"
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "_id": "5efdbc00d11162a4605ad7ba",
                                "type": "trophy",
                                "name": "Trophy 1",
                                "description": "",
                                "pos": 1,
                                "level": 1
                              }
                            ]
                          }
                        , undefined,2)
                      }</span>
                    </pre>
                    <hr />
                    <h3>Levels</h3>
                    <p>The levels on modules are basically to force users to complete a module before goes to the next one.</p>
                    <p>The lessons are always sequential, but the user can move between modules (if they are with the same level).</p>
                    <p>If there are modules with different levels, for example level 1, this means the user have to complete all lessons of all modules with level 1, before start lessons from modules wiht level 2.</p>
                    <div className="row img-size">
                      <div className="col-lg-1"/>
                      <div className="col-lg-4">
                        <img src="/media/documentation/schemas/modules_lessons.png" alt=""/>
                      </div>
                      <div className="col-lg-7"/>
                    </div>
                    <p>At the moment we only allow 4 different levels, and between modules with different levels, we should create a module with a trophy.</p>
                    <hr />
                    <h3>Complete lessons</h3>
                    <p>To indicate that a user finished a lesson, the endpoint <code>PUT /lesson/:lessonId/done</code> must be called.</p>
                    <p>This endpoint will generate a lesson status item on the DB to save the info that the user finished that lesson.</p>
                    <hr />
                    <h3>Quizzes</h3>
                    <p>To users answer a quiz the endpoint <code>PUT /lesson/quiz/:quizId/answer/:answerId</code> must be called.</p>
                    <p>This endpoint will generate a quiz status item on the DB to save the answer of that the user. Then the API will populate the quiz status of the user on the listing or get endpoints</p>
                    <p>There is also an endpoint that could be user to check the quiz status, for example if the user passed the quiz or not (when <code>quizGoal</code> field is greater than zeo)</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">GET /lesson/:lessonId/quiz/results</span>
                      </code>
                    </pre>
                    <p>In case the user didn't answer correctly all answer to reach the percentage of the <code>quizGoal</code>, it should call the endpoint to reset all answers, and allow user to answer again the quiz.</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">DELETE /lesson/:lessonId/user/results</span>
                      </code>
                    </pre>
                    <hr />
                  </div>
                  <div id="Academy_SCORM">
                    <h2>SCORM Courses</h2>
                    <hr/>
                  </div>
                </div>
                <div className="api-ref-info-container"  id="Perform">
                  <div id="Perform_Overview">
                    <h1>Perform</h1>
                    <p>Set personalized team journeys based on your company needs and create challenges, surveys inside the company to activate the positive peer effect.</p>
                    <hr/>
                  </div>
                  <div id="Perform_Journeys">
                    <h2>Journeys</h2>
                    <p>Journeys are mainly used to combine multiple ressourses from Bravon platform an create a list of multiple steps that the user has to follow sequentially (or not)</p>
                    <hr/>
                    <h3>Schema Map</h3>
                    <div className="row img-size">
                      <div className="col-lg-1"/>
                      <div className="col-lg-10">
                        <img src="/media/documentation/schemas/db_star_schema-journeys.png" alt=""/>
                      </div>
                      <div className="col-lg-1"/>
                    </div>
                    <p><code>Journey</code>: Main entity that contains the global information about the journey</p>
                    <p><code>Journey Step</code>: The Steps contains the information about what the user should do</p>
                    <p><code>Journey Step Status</code>: This entity is used to save a status that the step will appear as done to a specific user</p>
                    <hr/>
                    <h3>Create/Edit Journeys</h3>
                    <p>To create or edit journeys and/or steps, would be possible with the same endpoint.</p>
                    <h6>Although journeys and steps are separated entities on database, we can use the endpoint to create or edit a journey to manage steps too.</h6>
                    <p>Endpoints to Create: </p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">POST /journey</span>
                      </code>
                    </pre>
                    <p>Endpoints to Edit: </p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <code>
                        <span className="pln">PUT /journey/:journeyId</span>
                      </code>
                    </pre>
                    <p>Body example to create a new journey and steps.</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <span className="pln">{
                        JSON.stringify(
                          {
                            "isPublic": true,
                            "title": "Journey Test",
                            "summary": "Journey Description",
                            "description": "Journey Description",
                            "motivation": "Journey Motivation",
                            "image": {
                              "url": "https://s3-eu-central-1.amazonaws.com/avno-gamification-dev/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "medium": "https://s3-eu-central-1.amazonaws.com/avno-gamification-dev/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "thumb": "https://s3-eu-central-1.amazonaws.com/avno-gamification-dev/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "tags": [
                              "HTML",
                              "CSS",
                              "Javascript"
                            ],
                            "isSequential": true,
                            "targetUsers": [],
                            "targetTeams": [],
                            "seo": {
                              "title": "Journey Test",
                              "description": "Journey Description",
                              "keywords": [
                                "HTML",
                                "css"
                              ],
                              "image": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "certificate": {
                              "isActive": true,
                              "color": "#6edc07",
                              "link": "bravon.io",
                              "logo": {
                                "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                                "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                                "url": "https://s3.eu-central-1.amazonaws.com/media/photos/54e7bd4ab0fc0d97cbf4388f51489b5a.png"
                              },
                              "signature": {
                                "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                                "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                                "url": "https://s3.eu-central-1.amazonaws.com/media/photos/9b1bb8b2335837a994f35125734fd387.png"
                              },
                              "entityName": "Bravon",
                              "legalInfo": "In accordance with our GDPR obligations, please be informed that your data will only be stored. If you have any questions, please contact info@bravon.io"
                            },
                            "steps": [
                              {
                                "type": "task",
                                "title": "Task Title",
                                "description": "Task description",
                                "pos": 0,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "type": "profile_info",
                                "pos": 1,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "type": "course",
                                "pos": 2,
                                "level": 1,
                                "maxDays": 10,
                                "course": "5cab79a5460eb22ed2e9c8d9"
                              },
                              {
                                "type": "game",
                                "pos": 3,
                                "level": 1,
                                "maxDays": 10,
                                "game": "5cab79a5460eb22ed2e9c8d9",
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "type": "survey",
                                "pos": 4,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": "5cab8178460eb22ed2e9c8ed",
                                "surveyContext": "5cab8178460eb22ed2e9c8f",
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "type": "trophy",
                                "pos": 5,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": "winner",
                                "trophyCustom": null
                              }
                            ]
                          }
                        , undefined,2)
                      }</span>
                    </pre>
                    <p>Body example to edit a journey and steps.</p>
                    <pre className="prettyprint language-html prettyprinted" >
                      <span className="pln">{
                        JSON.stringify(
                          {
                            "isPublic": true,
                            "title": "Journey Test",
                            "summary": "Journey Description",
                            "description": "Journey Description",
                            "motivation": "Journey Motivation",
                            "image": {
                              "url": "https://s3-eu-central-1.amazonaws.com/avno-gamification-dev/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "medium": "https://s3-eu-central-1.amazonaws.com/avno-gamification-dev/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg",
                              "thumb": "https://s3-eu-central-1.amazonaws.com/avno-gamification-dev/media/photos/thumbs/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "tags": [
                              "HTML",
                              "CSS",
                              "Javascript"
                            ],
                            "isSequential": true,
                            "targetUsers": [],
                            "targetTeams": [],
                            "seo": {
                              "title": "Journey Test",
                              "description": "Journey Description",
                              "keywords": [
                                "HTML",
                                "css"
                              ],
                              "image": "https://s3-eu-central-1.amazonaws.com/media/photos/19bf8b77c702f0c5c58e5fe94724ef9a.jpg"
                            },
                            "certificate": {
                              "isActive": true,
                              "color": "#6edc07",
                              "link": "bravon.io",
                              "logo": {
                                "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                                "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/54e7bd4ab0fc0d97cbf4388f51489b5a.png",
                                "url": "https://s3.eu-central-1.amazonaws.com/media/photos/54e7bd4ab0fc0d97cbf4388f51489b5a.png"
                              },
                              "signature": {
                                "thumb": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                                "medium": "https://s3.eu-central-1.amazonaws.com/media/photos/thumbs/9b1bb8b2335837a994f35125734fd387.png",
                                "url": "https://s3.eu-central-1.amazonaws.com/media/photos/9b1bb8b2335837a994f35125734fd387.png"
                              },
                              "entityName": "Bravon",
                              "legalInfo": "In accordance with our GDPR obligations, please be informed that your data will only be stored. If you have any questions, please contact info@bravon.io"
                            },
                            "steps": [
                              {
                                "_id": "5cab79a5460eb22ed2e9c8aa",
                                "type": "task",
                                "title": "Task Title",
                                "description": "Task description",
                                "pos": 0,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "type": "task",
                                "title": "New Task",
                                "description": "New Task description",
                                "pos": 1,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "_id": "5cab79a5460eb22ed2e9c8ab",
                                "type": "profile_info",
                                "pos": 2,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "_id": "5cab79a5460eb22ed2e9c8ac",
                                "type": "course",
                                "pos": 3,
                                "level": 1,
                                "maxDays": 10,
                                "course": "5cab79a5460eb22ed2e9c8d9"
                              },
                              {
                                "_id": "5cab79a5460eb22ed2e9c8ae",
                                "type": "game",
                                "pos": 4,
                                "level": 1,
                                "maxDays": 10,
                                "game": "5cab79a5460eb22ed2e9c8d9",
                                "survey": null,
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "_id": "5cab79a5460eb22ed2e9c8af",
                                "type": "survey",
                                "pos": 5,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": "5cab8178460eb22ed2e9c8ed",
                                "surveyContext": "5cab8178460eb22ed2e9c8f",
                                "trophy": null,
                                "trophyCustom": null
                              },
                              {
                                "_id": "5cab79a5460eb22ed2e9c8ae",
                                "type": "trophy",
                                "pos": 6,
                                "level": 1,
                                "maxDays": 10,
                                "game": null,
                                "survey": null,
                                "trophy": "winner",
                                "trophyCustom": null
                              }
                            ]
                          }
                          , undefined,2)
                      }</span>
                    </pre>
                    <hr />
                    <h3>Complete steps</h3>
                    <p>Most of the step types are automatically set as done byt the API, when the user is using the UI to complete their tasks.</p>
                    <p>However, when the step type is a task, you should use the endpoint<code>PUT /journey/step/:stepId/done</code> to set the task as done by the user</p>
                    <p>On the case of being a step with a task, it has an internal flow that requires an admin to validate that the task was really done by the user.</p>
                    <hr />
                  </div>
                </div>
                {/*<div className="api-ref-info-container">*/}
                {/*  <h1>Authentication</h1>*/}
                {/*  <p>The Bravon API uses to authenticate requests. You can view and manage*/}
                {/*    your API keys in the Stripe Dashboard.</p>*/}

                {/*  <h2>Authentication</h2>*/}
                {/*  <p>Test mode secret keys have the prefix <code>sk_test_</code> and live mode secret keys have the prefix <code>sk_live_</code>. Alternatively, you can use restricted API keys for granular*/}
                {/*    permissions.</p>*/}
                {/*  <p>Your API keys carry many privileges, so be sure to keep them secure! Do not share your secret API keys in publicly accessible areas such as GitHub, client-side code, and so forth.</p>*/}
                {/*  <p>Authentication to the API is performed via HTTP Basic Auth. Provide your API key as the basic auth username value. You do not need to provide a password.</p>*/}
                {/*  <p>If you need to authenticate via bearer auth (e.g., for a cross-origin request), use <code>-H "Authorization: Bearer sk_test_4eC39HqLyjWDarjtT1zdp7dc"</code> instead of <code>-u sk_test_4eC39HqLyjWDarjtT1zdp7dc</code>.</p>*/}
                {/*  <p>All API requests must be made over HTTPS Calls made over plain HTTP will fail. API requests without authentication will also fail.</p>*/}
                {/*  <pre className="prettyprint language-html prettyprinted" >*/}
                {/*      <code>*/}
                {/*       <span className="pln">{JSON.stringify(codeExp, undefined,2) }</span>*/}
                {/*      </code>*/}
                {/*  </pre>*/}

                {/*  <hr/>*/}

                {/*  <h2>Contents </h2>*/}

                {/*  <p>Test mode secret keys have the prefix <code>sk_test_</code> and live mode secret keys have the prefix <code>sk_live_</code>. Alternatively, you can use restricted API keys for granular*/}
                {/*    permissions.</p>*/}

                {/*  <ul className="list-documnetation">*/}
                {/*    <li>*/}
                {/*      <a href="#basic-standard-and-advanced-apis">Basic, Standard and Advanced API levels</a> - understand*/}
                {/*      their different capabilities*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <strong><a href="#getting-started">Getting Started with the Number Insight API</a></strong> - try it*/}
                {/*      out*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#guides">Guides</a> - learn how to use the Number Insight API*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#code-snippets">Code Snippets</a> - code snippets to help with specific tasks*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#use-cases">Use Cases</a> - detailed use cases with code examples*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="#reference">Reference</a> - complete API documentation*/}
                {/*    </li>*/}
                {/*  </ul>*/}


                {/*  <h2>Images </h2>*/}

                {/*  <p>Test mode secret keys have the prefix <code>sk_test_</code> and live mode secret keys have the prefix <code>sk_live_</code>. Alternatively, you can use restricted API keys for granular*/}
                {/*    permissions.</p>*/}

                {/*  <div className="row img-size">*/}

                {/*    <div className="col-lg-6">*/}
                {/*      <img src="/media/stcok-900x600/12.jpg" alt=""/>*/}
                {/*    </div>*/}

                {/*    <div className="col-lg-6">*/}
                {/*      <img src="/media/stcok-900x600/12.jpg" alt=""/>*/}
                {/*    </div>*/}

                {/*  </div>*/}

                {/*  <hr/>*/}

                {/*  <h2>Warnings </h2>*/}

                {/*  <div>*/}
                {/*    <MySnackbarContentWrapper*/}
                {/*        variant="error"*/}
                {/*        className={classes.margin}*/}
                {/*        message="This is an error message!"*/}
                {/*    />*/}
                {/*    <MySnackbarContentWrapper*/}
                {/*        variant="warning"*/}
                {/*        className={classes.margin}*/}
                {/*        message="This is a warning message!"*/}
                {/*    />*/}
                {/*    <MySnackbarContentWrapper*/}
                {/*        variant="info"*/}
                {/*        className={classes.margin}*/}
                {/*        message="This is an information message!"*/}
                {/*    />*/}
                {/*    <MySnackbarContentWrapper*/}
                {/*        variant="success"*/}
                {/*        className={classes.margin}*/}
                {/*        message="This is a success message!"*/}
                {/*    />*/}
                {/*  </div>*/}

                {/*</div>*/}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
